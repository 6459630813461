<template>
  <div class="inner-section application-form-wrapper">
    <bread-cumb />
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenBtriService.tea_plant_stock_out') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-overlay :show="loading">
            <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
              <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Year" vid="fiscal_year_id" rules="required|min_value:1">
                    <b-form-group label-for="fiscal_year_id" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="fiscal_year_id"
                        v-model="formData.fiscal_year_id"
                        :options="fiscalYearList"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Stock In Date" vid="stock_out_date" rules="required">
                    <b-form-group
                      class="row"
                      label-for="stock_out_date"
                      slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('stock_management.stock_out_date') }}  <span class="text-danger">*</span>
                      </template>
                      <date-picker
                        id="stock_out_date"
                        v-model="formData.stock_out_date"
                        class="form-control"
                        :placeholder="$t('globalTrans.select_date')"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :class="errors[0] ? 'is-invalid' : ''"
                        :locale="currentLocale"
                      >
                      </date-picker>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <div class="add-more-area border-top border-dark pt-3">
                <b-row>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Made Tea Type" vid="clone_name_id" rules="">
                      <b-form-group label-for="clone_name_id" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                          {{ $t('teaGardenBtriService.clone_variety') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          id="clone_name_id"
                          v-model="formDataAddMore.clone_name_id"
                          :options="masterCloneNameList"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Stock Quantity" vid="current_stock" rules="">
                      <b-form-group label-for="current_stock" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                          {{ $t('teaGardenBtriService.current_stock') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="current_stock"
                          v-model="formDataAddMore.current_stock"
                          :state="errors[0] ? false : (valid ? true : null)"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="checkStock()"
                          min="0"
                          readonly
                        >
                        </b-form-input>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Stock Quantity" vid="approved_quantity" rules="">
                      <b-form-group label-for="approved_quantity" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                          {{ $t('globalTrans.quantity') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="approved_quantity"
                          v-model="formDataAddMore.approved_quantity"
                          :state="errors[0] ? false : (valid ? true : null)"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keyup="checkStock(index)"
                          min="0"
                        >
                        </b-form-input>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" class="text-right">
                    <b-button type="button" size="sm" class="btn btn-success" @click="addItem">
                      <i class="ri-add-circle-fill"></i> {{ $t('globalTrans.add_more') }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-table-simple bordered hover small responseive class="mt-3">
                  <b-thead>
                    <b-tr>
                      <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                      <b-th>{{ $t('teaGardenBtriService.clone_variety') }}</b-th>
                      <b-th>{{ $t('teaGardenBtriService.current_stock') }}</b-th>
                      <b-th>{{ $t('globalTrans.quantity') }}</b-th>
                      <b-th>{{ $t('globalTrans.action') }}</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <template v-if="formData.details.length">
                      <b-tr v-for="(item, index) in formData.details" :key="index">
                        <b-td>{{ $n(index + 1) }}</b-td>
                        <b-td>{{ getCloneName(item.clone_name_id) }}</b-td>
                        <b-td>{{ item.current_stock }}</b-td>
                        <b-td>
                          <ValidationProvider name="Stock Quantity" vid="approved_quantity" rules="required|min_value:0">
                            <b-form-group label-for="approved_quantity" slot-scope="{ valid, errors }">
                              <b-form-input
                                id="approved_quantity"
                                v-model="item.approved_quantity"
                                :state="errors[0] ? false : (valid ? true : null)"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                @keyup="approvalCalculation(index)"
                                min="0"
                              >
                              </b-form-input>
                              <div class="invalid-feedback d-block">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-td>
                        <b-td>
                          <b-button type="button" size="sm" class="btn btn-danger" @click="deleteItem(index)">
<!--                            <i class="ri-delete-bin-line"></i>-->
                            <i class="ri-close-line"></i>
                          </b-button>
                        </b-td>
                      </b-tr>
                    </template>
                    <template v-else>
                      <b-tr>
                        <b-td class="text-center" colspan="5">{{ $t('globalTrans.noDataFound') }}</b-td>
                      </b-tr>
                    </template>
                    <b-tr v-if="grantTotalShow">
                      <b-td colspan="2"><strong>{{ $t('globalTrans.grand_total') }}</strong></b-td>
                      <b-td class="text-right"><strong>{{ $n(getTotalQty()) }}</strong></b-td>
                      <b-td colspan="2"></b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
              <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                  <ValidationProvider name="Remarks" vid="remarks" rules="">
                    <b-form-group label-for="remarks" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('globalTrans.reason') }}
                      </template>
                      <b-form-textarea
                        id="remarks"
                        v-model="formData.remarks"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </b-form-textarea>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-row class="text-right mt-3">
                <b-col>
                    <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                    <b-button variant="danger" class="btn-sm" @click="back">{{ $t('globalTrans.cancel') }}</b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-overlay>
        </ValidationObserver>
      </template>
    </body-card>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { stockOutStore, getClonePrice } from '../../../api/routes'
import { helpers } from '@/utils/helper-functions'
import BreadCumb from '@/components/BreadCumb.vue'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
  mixins: [teaGardenService],
  name: 'Form',
  components: { BreadCumb },
  data () {
    return {
      grantTotalShow: false,
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      formData: {
        fiscal_year_id: this.$store.state.currentFiscalYearId,
        stock_out_date: helpers.currentDate(),
        remarks: '',
        details: []
      },
      formDataAddMore: {
        current_stock: 0,
        clone_name_id: 0,
        approved_quantity: 0 // This is stock out quantity
      }
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    masterCloneNameList () {
      return this.$store.state.TeaGardenService.commonObj.masterCloneNameList.filter(item => item.status === 1)
    }
  },
  watch: {
    'formDataAddMore.clone_name_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.clone_name_error = ''
        this.getClonePrice()
      }
    }
  },
  created () {
    if (this.$route.query.id) {
      this.getFormData(this.$route.query.id)
    }
  },
  methods: {
    async saveUpdate () {
      if (!this.formData.details.length) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'Please check at least one stock item'
        })
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const loadingState = { loading: false, listReload: false }
      const result = await RestApi.postData(teaGardenServiceBaseUrl, stockOutStore, this.formData)

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$router.push('tea-plant-stock-out')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    isNumber (evt) {
        helpers.isNumber(evt)
    },
    back () {
      this.$router.go(-1)
    },
    getCloneName (id) {
      const data = this.masterCloneNameList.find(item => item.value === id)
      if (data) {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      }
    },
    addItem () {
      if (!(this.formDataAddMore.clone_name_id && this.formDataAddMore.approved_quantity)) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'Please select Clone and quantity'
        })
      }
      const obj = this.formData.details.find(item => item.clone_name_id === this.formDataAddMore.clone_name_id)
      if (obj !== undefined) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'This Clone already exists'
        })
      }
      this.formData.details.push(this.formDataAddMore)
      this.formDataAddMore = {
        current_stock: 0,
        clone_name_id: 0,
        approved_quantity: 0
      }
    },
    deleteItem (index) {
      this.formData.details.splice(index, 1)
    },
    getTotalQty () {
      let totalQty = 0
      this.formData.details.forEach(item => {
        totalQty += parseFloat(item.approved_quantity)
      })
      return totalQty
    },
    async getClonePrice () {
      if (this.formDataAddMore.clone_name_id > 0) {
        this.loading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        result = await RestApi.getData(teaGardenServiceBaseUrl, getClonePrice, this.formDataAddMore)
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        if (result.success) {
          this.formDataAddMore.current_stock = result.stockQty ? result.stockQty.stock_quantity : 0
        } else {
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: 'Clone Price not Found!!!',
            color: '#D6E09B'
          })
        }
        this.loading = false
      }
    },
    checkStock () {
      this.currentStock = this.formDataAddMore.current_stock
      if ((parseInt(this.formDataAddMore.approved_quantity) > this.currentStock)) {
        const actualQty = this.currentStock
        this.$nextTick(() => {
          this.formDataAddMore.approved_quantity = parseInt(actualQty)
        })
      } else {
        this.formDataAddMore.approved_quantity = parseInt(this.formDataAddMore.approved_quantity)
      }
    },
    approvalCalculation (index) {
      const obj = this.formData.details[index]
      this.currentStock = obj.current_stock

      if ((parseInt(obj.approved_quantity) > this.currentStock)) {
        const actualQty = this.currentStock
        this.$nextTick(() => {
          obj.approved_quantity = parseInt(actualQty)
        })
      }
    }
  }
}
</script>
